.deleted {
  color: red;
  text-decoration: line-through;
}
.inserted {
  color: green;
  text-decoration: underline;
}
.deleted.accepted {
  display: none;
  user-select: none;
}
