.landing-page {
  @bgColor: #f7f7f7;
  @titleColor: fade(#000, 85);

  .ant-btn {
    min-height: 40px;
  }

  h2,
  h3 {
    text-align: center;
    font-weight: normal;
  }
  h2 {
    font-size: 30px;
    line-height: 38px;
    color: @titleColor;
    margin-top: 88px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: @titleColor;
  }
  .line {
    width: 40px;
    height: 2px;
    display: block;
    margin: 16px auto 72px;
  }

  header {
    min-height: 110px;
    box-shadow: none;
    .header-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      height: 50px;
    }
    .sign-btns {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .landing-banner {
    .banner-left {
      padding: 0 2em 2em 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .explore-btn {
        margin-top: 20px;
        min-height: 50px;
        min-width: 130px;
        font-size: 18px;
      }
    }
  }
  .landing-content {
    & > div {
      min-height: 70vh;
      padding: 40px;
    }
    & > :nth-of-type(odd) {
      /* background: #fff; */
      background: var(--bb5-primary);
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      div {
        color: #fff;
      }
      .line {
        background: #fff;
      }
    }
    & > :nth-of-type(even) {
      background-color: @bgColor;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      div {
        color: var(--bb5-brown);
      }
      .line {
        background: var(--bb5-brown);
      }
    }
  }
  .landing-section {
    h3 {
      margin-bottom: 1.5em;
    }
    display: flex;
    align-items: center;
  }
  .col {
    padding: 0 3em !important;
    font-size: 16px;
    ul {
      padding-left: 16px;
      margin-bottom: 45px;
    }
    p {
      color: rgba(0, 0, 0, 0.43);
    }
  }

  .section-2 {
    .col {
      .content-wrapper {
        /* height: 400px; */
        padding: 16px;
        .image {
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:hover {
          .code-wrapper {
            opacity: 1;
          }
        }
      }
      .code-wrapper {
        position: absolute;
        background: fade(#fff, 90);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
        p {
          color: var(--bb5-brown) !important;
        }
        h4 {
          font-size: 16px;
          font-weight: normal;
          margin: 72px auto 12px;
          color: @titleColor;
        }
      }
    }
  }

  .section-3 {
    flex-direction: column;
    .col {
      margin-bottom: 50px;
      .image {
        margin-bottom: 20px;
        img {
          height: 30vh;
        }
      }
    }
  }

  /* Specials */
  .home-hover {
    /* border: 1px solid #e9e9e9; */
    transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1),
      box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 2px 24px fade(#000, 10);
      border-color: #fff;
    }
  }

  .landing-footer {
    min-height: 336px;
    padding-top: 50px;
    background: var(--bb5-brown);
    color: fade(#fff, 85);
    font-size: 14px;

    .col {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .footer-links {
      list-style: none;
    }

    .logo-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .footer-logo {
        height: 50px;
        margin-bottom: 5px;
      }
    }
  }
}
