.slate-table {
  margin: 4px 0;
  overflow: auto;
  border: solid 1px #000;
  width: 100%;
}
.slate-table .table-tr {
  border: none;
}
.slate-table .table-td {
  padding: 0;
  box-sizing: border-box;
  border: solid 1px #000;
  vertical-align: baseline;
  min-width: 32px;
  font-size: 1em;
}
/*.slate-table .table-td ::selection {
  color: inherit;
  background: none;
} */
.slate-table .table-td.selectedCell {
  background: #b9d3fc;
}
.slate-table .table-content {
  overflow: auto;
  padding-left: 5px;
}
