:root {
  --font: 'Montserrat';
}
#root {
  height: 100vh;
}
body {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-family: var(--font), sans-serif;
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: var(--main-bg-color);
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: var(--main-bg-color);
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.app-layout {
  height: 100vh;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider-children {
  border-right: 1px solid #e8e8e8;
}
/* Header Style */
.ant-layout-header {
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #262626;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.ant-form-item-label {
  white-space: normal;
}

/* Main */
.pointer,
.link {
  cursor: pointer;
}
a,
.link {
  color: #0469b9;
}
a:hover,
.link:hover,
a:focus,
.link:focus {
  color: #0469b9;
}
a:focus {
  text-decoration: none;
}
ol,
ul,
dl {
  padding-left: 30px;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-90 {
  width: 90% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.minw50 {
  min-width: 50px;
}

.box-shadow-none {
  box-shadow: none !important;
}

/*Margin Padding Styles*/
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 2rem !important;
}
.pt-5,
.py-5 {
  padding-top: 2rem !important;
}
.pr-5,
.px-5 {
  padding-right: 2rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}
.pl-5,
.px-5 {
  padding-left: 2rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1368px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 2rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 2rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 2rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 22px;
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.h1-lg {
  font-size: 28px;
}
.text-strikethrough {
  text-decoration: line-through !important;
}
.text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.fs-xxs {
  font-size: 8px;
}
/*10px*/
.fs-xs {
  font-size: 10px;
}
/*11px*/
.fs-11 {
  font-size: 11px;
}
/*12px*/
.fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.fs-md {
  font-size: 14px;
  line-height: 1;
}
/*16px*/
.fs-lg {
  font-size: 16px;
  line-height: 1;
}
/*20px*/
.fs-xl {
  font-size: 20px;
  line-height: 1;
}
/*24px*/
.fs-xxl {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 1199px) {
  .fs-xxl {
    font-size: 20px;
  }
}
/*28px*/
.fs-2xl {
  font-size: 28px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .fs-2xl {
    font-size: 20px !important;
  }
}
/*30px*/
.fs-xxxl {
  font-size: 30px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .fs-xxxl {
    font-size: 28px;
  }
}
@media screen and (max-width: 1199px) {
  .fs-xxxl {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .fs-xxxl {
    font-size: 24px;
  }
}
/*40px*/
.fs-xlxl {
  font-size: 40px;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .fs-xlxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .fs-xlxl {
    font-size: 28px;
  }
}
/*50px*/
.fs-icon-lg {
  font-size: 50px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .fs-icon-lg {
    font-size: 38px;
  }
}
@media screen and (max-width: 575px) {
  .fs-icon-lg {
    font-size: 30px;
  }
}
/*54px*/
.fs-iconcard {
  font-size: 54px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .fs-iconcard {
    font-size: 34px;
  }
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-first-cap::first-letter {
  text-transform: uppercase;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-medium {
  font-weight: 500;
}
.font-weight-semi-bold {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-heavy {
  font-weight: 900;
}
.font-italic {
  font-style: italic;
}
.letter-spacing-base {
  letter-spacing: 3px;
}
.letter-spacing-lg {
  letter-spacing: 6px;
}
.letter-spacing-xl {
  letter-spacing: 8px;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*flex Styles*/
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-1 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/*Display Flex Classes*/
.flex-row {
  display: flex;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-column {
  display: flex;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.justify-content-start {
  -webkit-justify-content: flex-start !important;
  -ms-justify-content: flex-start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-justify-content: flex-end !important;
  -ms-justify-content: flex-end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-justify-content: center !important;
  -ms-justify-content: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-justify-content: space-between !important;
  -ms-justify-content: space-between !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -webkit-justify-content: space-around !important;
  -ms-justify-content: space-around !important;
  justify-content: space-around !important;
}
.align-items-start {
  -webkit-align-items: flex-start !important;
  -ms-align-items: flex-start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -webkit-align-items: flex-end !important;
  -ms-align-items: flex-end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -webkit-align-items: center !important;
  -ms-align-items: center !important;
  align-items: center !important;
}
.align-items-stretch {
  -webkit-align-items: stretch !important;
  -ms-align-items: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -webkit-align-content: flex-start !important;
  -ms-align-content: flex-start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -webkit-align-content: flex-end !important;
  -ms-align-content: flex-end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -webkit-align-content: center !important;
  -ms-align-content: center !important;
  align-content: center !important;
}
.align-content-between {
  -webkit-align-content: space-between !important;
  -ms-align-content: space-between !important;
  align-content: space-between !important;
}
.align-content-around {
  -webkit-align-content: space-around !important;
  -ms-align-content: space-around !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -webkit-align-content: stretch !important;
  -ms-align-content: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -webkit-align-self: auto !important;
  -ms-align-self: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -webkit-align-self: flex-start !important;
  -ms-align-self: flex-start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -webkit-align-self: flex-end !important;
  -ms-align-self: flex-end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -webkit-align-self: center !important;
  -ms-align-self: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -webkit-align-self: baseline !important;
  -ms-align-self: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -webkit-align-self: stretch !important;
  -ms-align-self: stretch !important;
  align-self: stretch !important;
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media screen and (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0;
}
.drawer-sidebar-dark .ant-drawer-content {
  background-color: #086880;
  color: #9ddae9;
}
/* .ant-drawer-content-wrapper {
  width: 280px !important;
} */
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 260px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.framed-layout .ant-drawer-wrapper-body {
  height: calc(100% - 2 * 20px);
}
.boxed-layout .ant-drawer {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right,
.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
/* Sidebar */
.ant-layout-sider {
  background-color: #fffffc;
  color: #545454;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 240px !important;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
.layout-sider-dark {
  background-color: #086880;
  color: #9ddae9;
}
.layout-sider-header {
  padding: 10px 30px 10px 70px;
  height: 72px;
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.layout-sider-header .site-logo {
  display: block;
}
.mini-custom-sidebar .layout-sider-header .site-logo {
  display: block;
}
.ant-layout-sider-collapsed .layout-sider-header .site-logo {
  display: none;
}
.layout-sider-header .linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .layout-sider-header .linebar {
  left: 20px;
}
@media screen and (max-width: 991px) {
  .layout-sider-header .linebar {
    display: none;
  }
}
.ant-layout-sider-collapsed .layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.drawer-sidebar .layout-sider-header {
  padding-left: 30px;
}
.drawer-sidebar-dark .layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.layout-sider-dark .layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.mini-custom-sidebar .layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.custom-sidebar .layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.mini-custom-sidebar.ant-layout-sider-lite .layout-sider-header,
.custom-sidebar.ant-layout-sider-lite .layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
@media screen and (max-width: 991px) {
  .layout-sider-header {
    padding-left: 30px;
  }
}
.layout-sider-dark .sidebar-content,
.drawer-sidebar-dark .sidebar-content {
  border-right: 0 none;
}
.layout-sider-scrollbar {
  height: calc(100vh - 72px - 145px) !important;
}
.framed-layout .layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .layout-sider-scrollbar {
  height: calc(100vh - 72px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.mini-custom-sidebar .layout-sider-scrollbar {
  height: calc(100vh - 72px - 206px) !important;
}
.framed-layout .mini-custom-sidebar .layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .mini-custom-sidebar .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .no-header-notifications + .layout-sider-scrollbar {
    height: calc(100vh - 72px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .no-header-notifications + .layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .sidebar-notifications,
.drawer-sidebar-dark .sidebar-notifications {
  border-bottom-color: #9ddae9;
}
@media screen and (max-width: 991px) {
  .sidebar-notifications {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .sidebar-notifications.no-header-notifications {
    display: block;
  }
}

/* Inline lists */
.list-inline {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 0;
  list-style: none;
}
.list-inline li {
  padding: 0 5px;
  display: inline-block;
}
.list-inline-lg {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 0;
  list-style: none;
}
.list-inline-lg li {
  padding: 0 10px;
  display: inline-block;
}

/* Sidebar, drawer, menu */
/* TBD */
.app-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #0469b9;
}
.ant-layout-sider-dark .app-nav,
.drawer-sidebar-dark .app-nav {
  color: #9ddae9;
}
.app-nav li {
  padding: 0 20px;
  font-size: 20px;
}
.app-nav li .icon {
  cursor: pointer;
}
.ant-layout-sider-collapsed .app-nav {
  display: none;
}
.mini-custom-sidebar .app-nav {
  display: block;
  margin-top: 15px;
}
.mini-custom-sidebar .app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.avatar-name {
  white-space: pre;
}
.ant-layout-sider-collapsed .avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .avatar-row {
  margin-bottom: 0 !important;
}
.avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.layouts-view .pointer {
  display: block;
  margin-bottom: 15px;
}
.layouts-view .pointer img {
  width: 100%;
}
.layouts-view .pointer.active {
  box-shadow: 0 0 10px 0 #0469b9;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
/* Ant Menu Style */
.ant-menu {
  color: #545454;
  background: #fff;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 20px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e1f3fa;
}
.ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-item .icon[class^='icon-']::before,
.ant-menu-item .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-sub .ant-menu-item .icon {
  font-size: 12px;
}
.ant-menu-item > a {
  color: #545454;
}
.ant-menu-item > a:hover {
  color: #0469b9;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #0469b9;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #0469b9;
}
.ant-menu-item-selected > a {
  color: #545454;
}
.ant-menu-item-selected > a:hover {
  color: #0469b9;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e1f3fa;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.drawer-sidebar .ant-menu-inline,
.drawer-sidebar .ant-menu-vertical,
.drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.inside-header-horizontal .ant-menu-item,
.inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1199px) {
  .inside-header-horizontal .ant-menu-item,
  .inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 20px;
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 0;
}
.ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px !important;
}
.ant-menu-submenu-popup {
  border-radius: 0;
  z-index: 1050 !important;
}
.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 20px !important;
}
.ant-menu-submenu-vertical .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-submenu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #545454, #545454);
}
.app-sidebar
  .ant-menu-submenu-vertical
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.app-sidebar
  .ant-menu-submenu-vertical-left
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.app-sidebar
  .ant-menu-submenu-vertical-right
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.app-sidebar
  .ant-menu-submenu-inline
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-drawer
  .ant-menu-submenu-vertical
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-drawer
  .ant-menu-submenu-vertical-left
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-drawer
  .ant-menu-submenu-vertical-right
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-drawer
  .ant-menu-submenu-inline
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  left: 60px;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #0469b9, #0469b9);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #0469b9;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #0469b9;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 70px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #0469b9;
  color: #0469b9;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a {
  color: #545454;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover {
  color: #0469b9;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #0469b9;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 30px 0 20px !important;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 40px !important;
}
.ant-menu-vertical .ant-menu-item .icon,
.ant-menu-vertical-left .ant-menu-item .icon,
.ant-menu-vertical-right .ant-menu-item .icon,
.ant-menu-inline .ant-menu-item .icon,
.ant-menu-vertical .ant-menu-submenu-title .icon,
.ant-menu-vertical-left .ant-menu-submenu-title .icon,
.ant-menu-vertical-right .ant-menu-submenu-title .icon,
.ant-menu-inline .ant-menu-submenu-title .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical-left .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical-right .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-inline .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class^='icon-']::before,
.ant-menu-vertical .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical-left .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical-right .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-inline .ant-menu-item .icon[class*=' icon-']::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class*=' icon-']::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 32px !important;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px !important;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #0469b9;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #0469b9, #0469b9);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #9ddae9;
  background: #086880;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #9ddae9;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #086880;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #086880;
}
.ant-menu-dark .ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item .icon[class^='icon-']::before,
.ant-menu-dark .ant-menu-item .icon[class*=' icon-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #9ddae9;
}
.ant-menu-dark .ant-menu-item {
  color: #9ddae9;
}
.ant-menu-dark .ant-menu-item > a {
  color: #9ddae9;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a {
  color: #17bde5;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #17bde5;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a {
  color: #17bde5;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow:before {
  background: #17bde5;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open
  .ant-menu-submenu-title
  span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #17bde5;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #17bde5;
}
.ant-menu-dark .ant-menu-item-selected span {
  color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #9ddae9;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #17bde5;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #262626;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-item .icon {
  font-size: 20px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon {
  font-size: 14px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, #0469b9, #0469b9);
}
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item-active
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item-active
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-submenu-open
  .ant-menu-submenu-open
  .ant-menu-item
  > a:hover
  > .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.navbar-nav li {
  padding: 0 5px;
}
.navbar-nav li a {
  display: block;
  padding: 4px 10px;
  background-color: #0469b9;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.profile-banner .navbar-nav li a {
  background-color: transparent;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
.navbar-nav li a * {
  color: #bfbfbf;
}
.navbar-nav li a:hover,
.navbar-nav li a:focus {
  background-color: #034d87;
  color: #ffffff;
}
.profile-banner .navbar-nav li a:hover,
.profile-banner .navbar-nav li a:focus {
  background-color: transparent;
  color: #17bde5;
}
.profile-banner .navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .profile-banner .navbar-nav li {
    padding: 0 6px;
  }
}
.profile-banner .navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .profile-banner .navbar-nav {
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.below-header-horizontal .header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.above-header-horizontal .header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #7a7a7a;
}
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #17bde5;
  color: #17bde5;
}
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #17bde5;
}
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title:before {
  content: '\e049';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: 'gaxon' !important;
  font-size: 31px;
  line-height: 5px;
}
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item:hover
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-selected
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item:hover
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-selected
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title:before {
  color: var(--main-bg-color);
}
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item:hover
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-selected
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title:before {
  color: #086880;
}
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.below-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.above-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before {
  color: #17bde5;
}
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu:hover
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-active
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-active
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-item-open
  > .ant-menu-submenu-title:before,
.inside-header-horizontal
  .header-horizontal-nav-curve
  .ant-menu-horizontal
  > .ant-menu-submenu-open
  > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -7px;
}
.submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #17bde5;
  color: #086880;
}
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #086880;
}
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #17bde5;
}
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #17bde5, #17bde5);
}
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.submenu-popup-curve.ant-menu-submenu-popup
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #17bde5, #17bde5);
}
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #17bde5;
}
.submenu-popup-curve.ant-menu-submenu-popup.inside-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected,
.submenu-popup-curve.ant-menu-submenu-popup.below-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected,
.submenu-popup-curve.ant-menu-submenu-popup.above-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: transparent;
  color: #17bde5;
}
.submenu-popup-curve.ant-menu-submenu-popup.inside-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  > a,
.submenu-popup-curve.ant-menu-submenu-popup.below-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  > a,
.submenu-popup-curve.ant-menu-submenu-popup.above-submenu-popup-curve
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  > a {
  color: #17bde5;
}
.mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.mini-custom-sidebar
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 20px !important;
}
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.mini-custom-sidebar
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  > span,
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.mini-custom-sidebar
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.mini-custom-sidebar
  .ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item.ant-menu-item-selected
  > a {
  color: #086880;
  background-color: #17bde5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mini-custom-sidebar
  .ant-menu-inline-collapsed
  > .ant-menu-submenu.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > span {
  color: #086880;
  background-color: #17bde5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mini-custom-sidebar .ant-menu-vertical .ant-menu-item .icon,
.mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item .icon,
.mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item .icon,
.mini-custom-sidebar .ant-menu-inline .ant-menu-item .icon,
.mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title .icon,
.mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title .icon,
.mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title .icon {
  margin-right: 0;
}
.no-header-submenu-popup .ant-menu-sub {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.container-wrap .layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .container-wrap .layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .container-wrap .layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .container-wrap .layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .container-wrap .layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .container-wrap .layout-footer-content {
    width: 1400px;
  }
}
/* Columns */
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  /* padding-right: 16px;
  padding-left: 16px; */
}

/* Rounded & Circle */
.rounded-xs {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
}
.rounded-sm {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}
.rounded-base {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
}
.rounded-lg {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}
.rounded-xxl {
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
}
.rounded-circle {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
.border-radius-top-left-0 {
  -moz-border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
}
.border-radius-top-right-0 {
  -moz-border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}
.border-radius-bottom-left-0 {
  -moz-border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.border-radius-bottom-right-0 {
  -moz-border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* Borders */
.border {
  border: solid 1px #e8e8e8;
}
.border-2 {
  border: solid 2px #e8e8e8;
}
.border-top {
  border-top: solid 1px #e8e8e8;
}
.border-bottom {
  border-bottom: solid 1px #e8e8e8;
}
.border-bottom-2 {
  border-bottom: solid 2px #e8e8e8;
}
.border-left {
  border-left: solid 1px #e8e8e8;
}
.border-right {
  border-right: solid 1px #e8e8e8;
}
.border-0 {
  border: 0 none !important;
}
.border-primary {
  border-color: #0469b9 !important;
}
.border-secondary {
  border-color: #17bde5 !important;
}
.border-success {
  border-color: #52c41a !important;
}
.border-success-dark {
  border-color: #368011 !important;
}
.border-info {
  border-color: #1890ff !important;
}
.border-warning {
  border-color: #fa8c16 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.border-light {
  border-color: #bfbfbf !important;
}
.border-dark {
  border-color: #595959 !important;
}
.border-white {
  border-color: #ffffff !important;
}
.border-grey {
  border-color: #bfbfbf !important;
}
.border-pink {
  border-color: #eb2f96 !important;
}
.border-green {
  border-color: #52c41a !important;
}
.border-red {
  border-color: #f5222d !important;
}
.border-amber {
  border-color: #fadb14 !important;
}
.border-blue {
  border-color: #1890ff !important;
}
.border-indigo {
  border-color: #3c1991 !important;
}
.border-purple {
  border-color: #722ed1 !important;
}
.border-orange {
  border-color: #fa8c16 !important;
}
.border-yellow {
  border-color: #fadb14 !important;
}
.border-teal {
  border-color: #1890ff !important;
}
.border-cyan {
  border-color: #13c2c2 !important;
}
.border-sepia {
  border-color: #7c3535 !important;
}
.border-geekblue {
  border-color: #030852 !important;
}

/* Sizes */
.size-200 {
  height: 200px !important;
  width: 200px !important;
  line-height: 200px !important;
}
.size-150 {
  height: 150px !important;
  width: 150px !important;
  line-height: 150px !important;
}
.size-120 {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px !important;
}
.size-100 {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px !important;
}
.size-90 {
  height: 90px !important;
  width: 90px !important;
  line-height: 90px !important;
}
.size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px !important;
}
.size-60 {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px !important;
}
.size-50 {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px !important;
}
.size-40 {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
}
.size-36 {
  height: 36px !important;
  width: 36px !important;
  line-height: 36px !important;
}
.size-30 {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}
.size-24 {
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
}
.size-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
}
.size-15 {
  height: 15px !important;
  width: 15px !important;
  line-height: 14px !important;
}
.size-10 {
  height: 10px !important;
  width: 10px !important;
  line-height: 10px !important;
}
.size-8 {
  height: 8px !important;
  width: 8px !important;
  line-height: 8px !important;
}

/* Positions */
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.left-0 {
  left: 0 !important;
}
.right-0 {
  right: 0 !important;
}
/* Tables */
/* Hover */
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e1f3fa;
}

/* Inputs */
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #2483c7;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  color: #545454;
  line-height: 0;
  transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.3;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}

/* color classes */
.bg-primary {
  background-color: #0469b9 !important;
}
.clickable .bg-primary:hover,
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #0469b9 !important;
}
.bg-primary-light {
  background-color: #489ed4 !important;
}
.bg-secondary {
  background-color: #17bde5 !important;
}
.clickable .bg-secondary:hover,
a.bg-secondary:hover,
a.bg-secondary:focus {
  background-color: #17bde5 !important;
}
.bg-success {
  background-color: #52c41a !important;
}
.clickable .bg-success:hover,
a.bg-success:hover,
a.bg-success:focus {
  background-color: #3f9714 !important;
}
.bg-success-dark {
  background-color: #368011 !important;
}
.clickable .bg-success-dark:hover,
a.bg-success-dark:hover,
a.bg-success-dark:focus {
  background-color: #2c6a0e !important;
}
.bg-info {
  background-color: #1890ff !important;
}
.clickable .bg-info:hover,
a.bg-info:hover,
a.bg-info:focus {
  background-color: #0076e4 !important;
}
.bg-warning {
  background-color: #fa8c16 !important;
}
.clickable .bg-warning:hover,
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #d87205 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
.clickable .bg-danger:hover,
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #e8e8e8 !important;
}
.clickable .bg-light:hover,
a.bg-light:hover,
a.bg-light:focus {
  background-color: #d9d9d9 !important;
}
.bg-dark {
  background-color: #000000 !important;
}
.clickable .bg-dark:hover,
a.bg-dark:hover,
a.bg-dark:focus {
  background-color: #1a1a1a !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.clickable .bg-white:hover,
a.bg-white:hover,
a.bg-white:focus {
  background-color: #f2f2f2 !important;
}
.bg-grey {
  background-color: #cccccc !important;
}
.clickable .bg-grey:hover,
a.bg-grey:hover,
a.bg-grey:focus {
  background-color: #b2b2b2 !important;
}
.bg-dark-grey {
  background-color: #666666 !important;
}
.clickable .bg-dark-grey:hover,
a.bg-dark-grey:hover,
a.bg-dark-grey:focus {
  background-color: #aaaaaa !important;
}
.bg-light-grey {
  background-color: #fafafa !important;
}
.clickable .bg-light-grey:hover,
a.bg-light-grey:hover,
a.bg-light-grey:focus {
  background-color: #eaeaea !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-pink {
  background-color: #eb2f96 !important;
}
.clickable .bg-pink:hover,
a.bg-pink:hover,
a.bg-pink:focus {
  background-color: #d3147d !important;
}
.bg-green {
  background-color: #52c41a !important;
}
.clickable .bg-green:hover,
a.bg-green:hover,
a.bg-green:focus {
  background-color: #3f9714 !important;
}
.bg-green-light {
  background-color: #caf5b5 !important;
}
.clickable .bg-green-light:hover,
a.bg-green-light:hover,
a.bg-green-light:focus {
  background-color: #8ae95b !important;
}
.bg-red {
  background-color: #f5222d !important;
}
.clickable .bg-red:hover,
a.bg-red:hover,
a.bg-red:focus {
  background-color: #da0a15 !important;
}
.bg-amber {
  background-color: #fadb14 !important;
}
.clickable .bg-amber:hover,
a.bg-amber:hover,
a.bg-amber:focus {
  background-color: #d6ba05 !important;
}
.bg-amber-light {
  background-color: #fdf2aa !important;
}
.clickable .bg-amber-light:hover,
a.bg-amber-light:hover,
a.bg-amber-light:focus {
  background-color: #fcea78 !important;
}
.bg-blue {
  background-color: #1890ff !important;
}
.clickable .bg-blue:hover,
a.bg-blue:hover,
a.bg-blue:focus {
  background-color: #0076e4 !important;
}
.bg-light-blue {
  background-color: #329cff !important;
}
.clickable .bg-light-blue:hover,
a.bg-light-blue:hover,
a.bg-light-blue:focus {
  background-color: #4ba9ff !important;
}
.bg-indigo {
  background-color: #3c1991 !important;
}
.clickable .bg-indigo:hover,
a.bg-indigo:hover,
a.bg-indigo:focus {
  background-color: #2a1166 !important;
}
.bg-purple {
  background-color: #722ed1 !important;
}
.clickable .bg-purple:hover,
a.bg-purple:hover,
a.bg-purple:focus {
  background-color: #5b25a7 !important;
}
.bg-orange {
  background-color: #fa8c16 !important;
}
.clickable .bg-orange:hover,
a.bg-orange:hover,
a.bg-orange:focus {
  background-color: #d87205 !important;
}
.bg-yellow {
  background-color: #fadb14 !important;
}
.clickable .bg-yellow:hover,
a.bg-yellow:hover,
a.bg-yellow:focus {
  background-color: #d6ba05 !important;
}
.bg-teal {
  background-color: #20c997 !important;
}
.clickable .bg-teal:hover,
a.bg-teal:hover,
a.bg-teal:focus {
  background-color: #199d76 !important;
}
.bg-cyan {
  background-color: #13c2c2 !important;
}
.clickable .bg-cyan:hover,
a.bg-cyan:hover,
a.bg-cyan:focus {
  background-color: #0e9494 !important;
}
.bg-sepia {
  background-color: #7c3535 !important;
}
.clickable .bg-sepia:hover,
a.bg-sepia:hover,
a.bg-sepia:focus {
  background-color: #582626 !important;
}
.bg-geekblue {
  background-color: #030852 !important;
}
.clickable .bg-geekblue:hover,
a.bg-geekblue:hover,
a.bg-geekblue:focus {
  background-color: #010321 !important;
}
.text-white {
  color: #ffffff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0469b9 !important;
}
.text-secondary {
  color: #17bde5 !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #17bde5 !important;
}
.text-success {
  color: #52c41a !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #49ad17 !important;
}
.text-success-dark {
  color: #368011 !important;
}
a.text-success-dark:hover,
a.text-success-dark:focus {
  color: #2c6a0e !important;
}
.text-info {
  color: #1890ff !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #0084fe !important;
}
.text-warning {
  color: #fa8c16 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #f17f05 !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #f32c1e !important;
}
.text-light {
  color: #ababab !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #787878 !important;
}
.text-light-grey {
  color: #cccccc !important;
}
a.text-light-grey:hover,
a.text-light-grey:focus {
  color: #bfbfbf !important;
}
.text-grey {
  color: #8c8c8c !important;
}
a.text-grey:hover,
a.text-grey:focus {
  color: #262626 !important;
}
.text-dark {
  color: #595959 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #4c4c4c !important;
}
.text-black {
  color: #000000 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #4c4c4c !important;
}
.text-pink {
  color: #eb2f96 !important;
}
a.text-pink:hover,
a.text-pink:focus {
  color: #e9188a !important;
}
.text-green {
  color: #52c41a !important;
}
a.text-green:hover,
a.text-green:focus {
  color: #49ad17 !important;
}
.text-red {
  color: #f5222d !important;
}
a.text-red:hover,
a.text-red:focus {
  color: #f30b17 !important;
}
.text-amber {
  color: #fadb14 !important;
}
a.text-amber:hover,
a.text-amber:focus {
  color: #efd005 !important;
}
.text-blue {
  color: #1890ff !important;
}
a.text-blue:hover,
a.text-blue:focus {
  color: #0084fe !important;
}
.text-indigo {
  color: #3c1991 !important;
}
a.text-indigo:hover,
a.text-indigo:focus {
  color: #33157b !important;
}
.text-purple {
  color: #722ed1 !important;
}
a.text-purple:hover,
a.text-purple:focus {
  color: #6729bc !important;
}
.text-orange {
  color: #fa8c16 !important;
}
a.text-orange:hover,
a.text-orange:focus {
  color: #f17f05 !important;
}
.text-yellow {
  color: #fadb14 !important;
}
a.text-yellow:hover,
a.text-yellow:focus {
  color: #efd005 !important;
}
.text-teal {
  color: #20c997 !important;
}
a.text-teal:hover,
a.text-teal:focus {
  color: #1cb386 !important;
}
.text-cyan {
  color: #13c2c2 !important;
}
a.text-cyan:hover,
a.text-cyan:focus {
  color: #11abab !important;
}
.text-muted {
  color: #8c8c8c !important;
}
.text-geekblue {
  color: #030852 !important;
}
a.text-geekblue:hover,
a.text-geekblue:focus {
  color: #10239e !important;
}
.text-sepia {
  color: #7c3535 !important;
}
a.text-sepia:hover,
a.text-sepia:focus {
  color: #7c3535 !important;
}
.icon-white {
  color: #ffffff !important;
}

/* Card */
.card-full {
  overflow: hidden;
}
.card-full .card-body {
  padding: 0;
}
.card-full .ant-card-body {
  padding: 0;
}
/*Media Object Classes*/
.media {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.media-body {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
/*Overflow styles*/
.overflow-auto {
  overflow: auto;
}

/*Time Lines Style*/
/*Antd Time Lines Style*/
.ant-timeline-item {
  padding: 0;
}
.ant-timeline-item:not(:last-child) {
  padding-bottom: 20px;
}
@media screen and (max-width: 575px) {
  .ant-timeline-item:not(:last-child) {
    padding-bottom: 15px;
  }
}
.timeline-info {
  position: relative;
}
.timeline-info:not(:last-child) {
  padding-bottom: 20px;
}
.timeline-info-day {
  margin-bottom: 15px;
  font-size: 12px;
  color: #595959;
}
.timeline-info .ant-timeline {
  padding-top: 0;
  padding-left: 0;
}
.timeline-info .ant-timeline-item-head-custom {
  padding: 0;
  margin-right: 15px;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.timeline-info .ant-timeline-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.timeline-info .ant-timeline-item-content {
  padding-left: 0;
  top: 0;
  margin-left: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #595959;
}
.timeline-info .ant-timeline-item-content .link {
  color: #262626;
}
.timeline-info .ant-timeline-item-content .link:hover,
.timeline-info .ant-timeline-item-content .link:focus {
  color: #0469b9;
}
.timeline-info .ant-timeline-item-tail {
  display: none;
}
.img-more {
  width: 60px;
  height: 40px;
  background-color: #ffffff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  border: solid 1px #e8e8e8;
}
/*Custom Time Lines Style*/
.timeline-left {
  float: left;
  margin-right: 20px;
}
.timeline-right {
  float: right;
  margin-left: 20px;
}
.timeline-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.timeline-section {
  margin-bottom: 25px;
  overflow: hidden;
}
.timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative;
}
.timeline-item:before {
  border-left: solid 2px #e8e8e8;
  content: '';
  left: 25px;
  position: absolute;
  top: 25px;
  bottom: -20px;
  width: 2px;
  z-index: 1;
}
.timeline-item:first-child:before {
  border-left-style: dashed;
}
.timeline-item:last-child:before {
  border-left-style: dashed;
  bottom: 25px;
}
.timeline-badge {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.timeline-badge.timeline-img {
  background-color: transparent;
}
.timeline-badge.timeline-img:after {
  display: none;
}
.timeline-badge:after {
  position: absolute;
  content: '';
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 4px solid #e8e8e8;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 4px;
  top: 4px;
  z-index: 1;
}
.timeline-badge [class^='']:before,
.timeline-badge [class*='']:before {
  line-height: 50px;
}
.timeline-img > img,
.timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #e8e8e8;
}
.timeline-panel:after,
.timeline-panel:before {
  content: '';
  position: absolute;
  border-style: solid;
  display: inline-block;
  top: 30px;
}
.timeline-panel:before {
  border-color: transparent #e8e8e8;
  border-width: 10px 17px 10px 0;
  left: -18px;
}
.timeline-panel:after {
  border-color: transparent #ffffff;
  border-width: 10px 16px 10px 0;
  left: -16px;
}
.timeline-no-padding {
  padding: 0;
}
.timeline-panel-header {
  margin-bottom: 10px;
}
.timeline-header-img {
  margin-bottom: 10px;
}
.timeline-inverted .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px;
}
.timeline-heading {
  overflow: hidden;
  margin-bottom: 10px;
}
.timeline-body {
  clear: both;
}
/*Gxtl Center Style*/
.timeline-center .timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px;
}
.timeline-center .timeline-item:before {
  left: 0;
}
.timeline-center .timeline-badge {
  left: -25px;
  right: auto;
}
.timeline-center .timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2;
}
.timeline-center .timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px;
}
.timeline-center .timeline-inverted:before {
  border-left: 0 none;
  border-right: solid 2px #e8e8e8;
  left: auto;
  right: -2px;
}
.timeline-center .timeline-inverted .timeline-badge {
  left: auto;
  right: -25px;
}
.timeline-center .timeline-inverted .timeline-panel:before {
  border-width: 10px 0 10px 17px;
  left: auto;
  right: -18px;
}
.timeline-center .timeline-inverted .timeline-panel:after {
  border-width: 10px 0 10px 16px;
  left: auto;
  right: -16px;
}
.timeline-center .timeline-inverted .timeline-time {
  position: absolute;
  top: 32px;
  right: -150px;
  left: auto;
  z-index: 2;
}
/*Gxtl Zigzag Style*/
.timeline-zigzag .timeline-item {
  padding-left: 150px;
}
.timeline-zigzag .timeline-item + .timeline-item {
  margin-top: -80px;
}
.timeline-zigzag .timeline-img {
  width: 90px;
  height: 90px;
  left: 5px;
}
.timeline-zigzag .timeline-img > img {
  width: 100%;
}
.timeline-zigzag .timeline-inverted .timeline-img {
  right: 5px !important;
}
.timeline-zigzag .timeline-item:before {
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px;
}
.timeline-zigzag .timeline-inverted {
  padding-left: 0;
  padding-right: 150px;
}
.timeline-zigzag .timeline-inverted:before {
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.timeline-zigzag .timeline-item:first-child:before {
  border-left-style: solid;
}
.timeline-zigzag .timeline-item:last-child:before {
  display: none;
}
.timeline-item:last-child:before {
  bottom: 25px;
}
@media screen and (max-width: 991px) {
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -60px;
  }
  .timeline-zigzag .timeline-item:before {
    bottom: 0;
  }
  .timeline-zigzag .timeline-item {
    padding-left: 120px;
  }
  .timeline-zigzag .timeline-inverted {
    padding-right: 120px;
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .timeline-center .timeline-item {
    float: none;
    padding-left: 80px;
    width: auto;
  }
  .timeline-center .timeline-item:before {
    left: 25px;
  }
  .timeline-center .timeline-badge {
    left: 0;
  }
  .timeline-center .timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0;
  }
  .timeline-center .timeline-inverted:before {
    border-left: solid 2px #e8e8e8;
    border-right: 0 none;
    left: 24px;
    right: auto;
  }
  .timeline-center .timeline-inverted .timeline-badge {
    right: auto;
    left: 0;
  }
  .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 10px 17px 10px 0 !important;
    left: -18px;
    right: auto;
  }
  .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 10px 16px 10px 0 !important;
    left: -16px;
    right: auto;
  }
  .timeline-inverted .timeline-panel-header {
    float: none;
  }
  .timeline-inverted .timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  .timeline-zigzag .timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible;
  }
  .timeline-zigzag .timeline-item {
    padding-left: 100px;
  }
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: 0;
  }
  .timeline-zigzag .timeline-item:before {
    transform: none;
    top: 25px;
    bottom: -25px;
    left: 45px;
  }
  .timeline-zigzag .timeline-item:last-child:before {
    bottom: 0;
  }
  .timeline-zigzag .timeline-inverted:before {
    transform: none;
  }
  .timeline-center .timeline-time,
  .timeline-center .timeline-inverted .timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px;
  }
  .timeline-center .timeline-time-item .timeline-panel::before,
  .timeline-center .timeline-time-item .timeline-panel::after {
    top: 10px;
  }
}
@media screen and (max-width: 399px) {
  .timeline-left {
    float: none;
    margin-right: 0;
  }
  .timeline-right {
    float: none;
    margin-left: 0;
  }
  .timeline-inverted .timeline-left {
    float: none;
    margin-right: 0;
  }
}

/*Button Styles*/
.ant-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}
.ant-btn-group .ant-btn:not(:last-child),
.ant-transfer-operation .ant-btn:not(:last-child) {
  margin-right: 0;
}
@media (max-width: 1599px) {
  .product-footer .ant-btn:not(:last-child) {
    margin-right: 6px;
  }
}
@media screen and (max-width: 575px) {
  .ant-btn {
    margin-bottom: 10px;
  }
}
.ant-input-search .ant-btn {
  margin-bottom: 0;
}
/* Avatar */
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 6px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
}
/* Custom */
.app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .app-login-container {
    padding-bottom: 20px;
  }
}
.app-login-container .loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.app-login-content .ant-input {
  background-color: var(--main-bg-color);
}
.app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #0469b9;
}
.app-login-content .btn {
  padding: 6px 35px !important;
  height: auto;
}
.app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .app-login-header {
    margin-bottom: 15px;
  }
}
.app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.app-logo-content > * {
  position: relative;
  z-index: 2;
}
.app-logo-content h1 {
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgb(71 98 119 / 70%);
}
.app-logo-content-bg img {
  width: 100%;
  height: 100%;
}

.entry-title {
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}
.entry-title:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1;
  height: 3px;
  width: 42px;
  background-color: #038fde;
}
.entry-header {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .entry-header {
    margin-bottom: 20px;
  }
}
.entry-header .entry-heading {
  margin-bottom: 0;
}
.entry-header .entry-description {
  margin-bottom: 0;
  font-size: 13px;
}

/* Featured content */

.featured-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.card-profile .featured-item:not(:last-child) {
  padding-bottom: 8px;
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .featured-item:not(:last-child) {
    margin-bottom: 27px;
  }
}
.featured-item:last-child .featured-thumb {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .featured-item:last-child .featured-thumb {
    margin-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .featured-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: normal;
    -ms-align-items: normal;
    align-items: normal;
  }
}
.featured-thumb {
  position: relative;
  margin-right: 25px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .featured-thumb {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .featured-thumb > img {
    width: 100% !important;
    height: auto !important;
  }
}
.featured-thumb .ant-tag {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  margin: 0;
}
.card-profile .featured-thumb {
  max-width: 130px;
}
@media (max-width: 575px) {
  .card-profile .featured-thumb {
    max-width: none;
  }
}
.featured-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1366px) {
  .featured-content {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .featured-content {
    font-size: 14px;
  }
  .card-profile .featured-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .featured-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.featured-content-right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: auto;
  text-align: right;
}
.card-profile .featured-content-right {
  font-size: 14px;
}
@media (max-width: 1199px) {
  .card-profile .featured-content-right {
    text-align: left;
    margin-left: 0;
  }
  .card-profile .featured-content-right h2 {
    font-size: 16px;
  }
  .card-profile .featured-content-right h2 .fs-lg {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .featured-content-right {
    margin-left: 0;
    text-align: left;
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .card-profile .featured-content-right h2 {
    font-size: 14px;
  }
}
/*Loader Styles*/
.ant-spin {
  margin-right: 16px;
  margin-bottom: 10px;
}
.loader-container {
  text-align: center;
  background-color: var(--main-bg-color);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 30px 50px;
  margin-right: 0;
  display: block;
}
