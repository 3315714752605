/* frank-ruhl-libre-500 - latin */
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-500.svg#FrankRuhlLibre')
      format('svg'); /* Legacy iOS */
}
/* frank-ruhl-libre-regular - latin */
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/frank-ruhl-libre-v12-latin/frank-ruhl-libre-v12-latin-regular.svg#FrankRuhlLibre')
      format('svg'); /* Legacy iOS */
}

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v18-latin/assistant-v18-latin-regular.svg#Assistant')
      format('svg'); /* Legacy iOS */
}
/* assistant-300 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/assistant-v18-latin/assistant-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/assistant-v18-latin/assistant-v18-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v18-latin/assistant-v18-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v18-latin/assistant-v18-latin-300.svg#Assistant')
      format('svg'); /* Legacy iOS */
}
/* assistant-500 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/assistant-v18-latin/assistant-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/assistant-v18-latin/assistant-v18-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v18-latin/assistant-v18-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v18-latin/assistant-v18-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v18-latin/assistant-v18-latin-500.svg#Assistant')
      format('svg'); /* Legacy iOS */
}

:root {
  --bb5-primary: #073843;
  --bb5-brown: #362222;
  --bb5-pale: #f5f4f1;
}
body {
  font-family: 'Assistant' sans-serif;
}

.layout-sider-scrollbar {
  height: calc(100vh - 72px - 145px) !important;
}

.ant-layout-sider.collapsed-sidebar {
  width: 0 !important;
}
.ant-layout-sider.collapsed-sidebar .ant-layout-sider-children {
  width: 0 !important;
}

@media screen and (max-width: 991px) {
  .framed-layout .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .layout-sider-scrollbar {
  height: calc(100vh - 72px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.mini-custom-sidebar .layout-sider-scrollbar {
  height: calc(100vh - 72px - 206px) !important;
}
.framed-layout .mini-custom-sidebar .layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .mini-custom-sidebar .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .layout-sider-scrollbar {
    height: calc(100vh - 200px) !important;
  }
}
@media screen and (max-width: 991px) {
  .no-header-notifications + .layout-sider-scrollbar {
    height: calc(100vh - 72px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .no-header-notifications + .layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}

.bg-pale {
  background-color: var(--bb5-pale);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Frank Ruhl Libre Medium' sans-serif;
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 26px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 15px;
}

.prim-bg {
  background: var(--bb5-primary);
}

.ant-btn {
  border-radius: 6px;
}
.ant-btn.ant-btn-primary {
  background-color: var(--bb5-primary);
  border-color: var(--bb5-primary);
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-color: var(--bb5-primary);
}

.prim-bg .ant-btn.ant-btn-primary {
  border-color: #fff;
}
.prim-bg .ant-btn.ant-btn-primary:hover {
  background-color: #0a4552;
}

.prim-bg .ant-btn.ant-btn-secondary {
  border-color: #fff;
}

.prim-bg .ant-btn.ant-btn-secondary:hover {
  color: var(--bb5-primary);
  background-color: rgba(255, 255, 255, 0.8);
}

.padded-page {
  padding: 32px 32px 0;
}
.padded-page-y {
  padding: 32px 0 0 0;
}
.clickable {
  cursor: pointer;
}
.site-logo {
  width: 50%;
  margin: 20px 0;
}
.sidebar-profile {
  margin: 0 10px 20px 0px;
  display: flex;
  justify-content: flex-end;
}
.modal-full {
  width: 90vw !important;
  height: 80vh;
  top: 30px;
}
.modal-full .ant-modal-body {
  min-height: 80vh;
}
.modal-lg {
  width: 85vw !important;
}
.modal-md {
  width: 70vw !important;
}

/* Custom adjustments */
.entry-title:before {
  content: unset;
}

/* Display offers */
.offer-item-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.offer-item-container > div:first-child {
  font-size: 0.85em;
  color: #aaa;
}
.offer-item-container > div:not(:first-child) {
  color: black;
}
.offer-item-container .no-info {
  color: #888;
}

.hidden-item {
  height: 100%;
  min-height: 30px;
  min-width: 50px;
  background-color: rgba(190, 190, 190, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.favourite-holder {
  font-size: 28px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
